@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?o9pau7");
  src: url("fonts/icomoon.eot?o9pau7#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?o9pau7") format("truetype"), url("fonts/icomoon.woff?o9pau7") format("woff"), url("fonts/icomoon.svg?o9pau7#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dice-five:before {
  content: "\e909";
}

.icon-dice-four:before {
  content: "\e90a";
}

.icon-dice-one:before {
  content: "\e90b";
}

.icon-dice-six:before {
  content: "\e90c";
}

.icon-dice-three:before {
  content: "\e90d";
}

.icon-dice-two:before {
  content: "\e90e";
}

.icon-external-link:before {
  content: "\e908";
}

.icon-address-book:before {
  content: "\e944";
}

.icon-user-plus:before {
  content: "\e973";
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-chevron-down:before {
  content: "\e906";
}

.icon-chevron-up:before {
  content: "\e907";
}

.icon-discord:before {
  content: "\e904";
}

.icon-book:before {
  content: "\e91f";
}

.icon-price-tags:before {
  content: "\e936";
}

.icon-compass:before {
  content: "\e94a";
}

.icon-users:before {
  content: "\e972";
}

.icon-search:before {
  content: "\e986";
}

.icon-magic-wand:before {
  content: "\e997";
}

.icon-accessibility:before {
  content: "\e9b2";
}

.icon-tree:before {
  content: "\e9bc";
}

.icon-chevron-right:before {
  content: "\e902";
}

.icon-chevron-left:before {
  content: "\e903";
}

.icon-folder-plus:before {
  content: "\e931";
}

.icon-folder-minus:before {
  content: "\e932";
}

.icon-plus:before {
  content: "\ea0a";
}

.icon-minus:before {
  content: "\ea0b";
}

.icon-cancel-circle:before {
  content: "\ea0d";
}

.icon-blocked:before {
  content: "\ea0e";
}

.icon-cross:before {
  content: "\ea0f";
}

.icon-checkmark:before {
  content: "\ea10";
}

.icon-arrow-left:before {
  content: "\ea40";
}

.icon-linkedin:before {
  content: "\eac9";
}

.icon-menu:before {
  content: "\e901";
}

.icon-logo:before {
  content: "\e900";
}

.icon-user:before {
  content: "\e971";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-github:before {
  content: "\eab0";
}

.icon-arrow-right:before {
  content: "\ea3c";
}

.icon-shield:before {
  content: "\e9b4";
}

.icon-power:before {
  content: "\e9b5";
}

.icon-star-full:before {
  content: "\e9d9";
}

.icon-heart:before {
  content: "\e9da";
}

.icon-pencil:before {
  content: "\e905";
}

.icon-dice:before {
  content: "\e915";
}

.icon-folder-download:before {
  content: "\e933";
}

.icon-folder-upload:before {
  content: "\e934";
}

.icon-pushpin:before {
  content: "\e946";
}

.icon-location:before {
  content: "\e947";
}

.icon-location-ok:before {
  content: "\e948";
}

.icon-hour-glass:before {
  content: "\e979";
}

.icon-spinner:before {
  content: "\e982";
}

.icon-enlarge:before {
  content: "\e989";
}

.icon-bin:before {
  content: "\e9ad";
}

.icon-arrow-up:before {
  content: "\ea3a";
}

.icon-arrow-down:before {
  content: "\ea3e";
}

.icon-leaf:before {
  content: "\e9a4";
}

.mdc-button {
  height: auto !important;
  padding: var(--arl-spacing-s) var(--arl-spacing-m) !important;
  background: var(--arl-background-black-light) !important;
}
.mdc-button .mdc-button__label {
  display: inline-flex;
  align-items: center;
  gap: var(--arl-spacing-s);
}

.mdc-icon-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.mat-mdc-icon-button {
  font-family: var(--arl-sans-serif-font);
  border-width: 0.1rem !important;
  border-style: solid !important;
}
.mat-mdc-icon-button:not(:disabled) {
  border-color: var(--mdc-icon-button-icon-color, inherit) !important;
}
.mat-mdc-icon-button.is-small {
  --mdc-icon-button-state-layer-size: 36px !important;
  --mdc-icon-button-icon-size: 18px !important;
  padding: 8px !important;
}

.mat-mdc-outlined-button {
  text-transform: uppercase !important;
}
.mat-mdc-outlined-button:not(:disabled) {
  border-color: var(--mdc-outlined-button-label-text-color, inherit) !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: var(--arl-black-color) !important;
  --mdc-dialog-supporting-text-color: var(--arl-white-color) !important;
  --mdc-dialog-supporting-text-font: var(--arl-sans-serif-font);
  --mdc-dialog-supporting-text-size: var(--arl-font-size-m);
  --mdc-dialog-supporting-text-line-height: var(--arl-line-height-m);
}

.mat-mdc-dialog-component-host > h3 {
  padding: var(--arl-spacing-m) !important;
  border-bottom: var(--arl-border-white) !important;
}

.mdc-dialog__surface {
  border: var(--arl-border);
  border-radius: var(--arl-radius) !important;
}

.mdc-dialog__content {
  padding: var(--arl-spacing-m) !important;
}

.mat-mdc-dialog-actions {
  justify-content: center !important;
  padding: var(--arl-spacing-m) !important;
  border-top: var(--arl-border-white) !important;
}

.mat-mdc-form-field-required-marker {
  margin-left: var(--arl-spacing-xs);
  color: var(--arl-red-color);
}

.mdc-text-field {
  border-radius: var(--arl-spacing-s) !important;
  border: var(--arl-border);
}

.mdc-text-field--filled {
  background: var(--arl-black-color) !important;
}

.mdc-text-field--disabled {
  border-color: rgba(255, 255, 255, 0.38) !important;
}

.mdc-line-ripple {
  display: none !important;
}

.mat-mdc-form-field-textarea-control {
  min-height: 10rem;
}

.mdc-menu-surface {
  border-radius: var(--arl-radius) !important;
}

.mat-mdc-option {
  min-height: 36px !important;
}

.cdk-overlay-pane {
  max-width: 50vw !important;
}
@media screen and (max-width: 674px) {
  .cdk-overlay-pane {
    max-width: 90vw !important;
  }
}

.mdc-switch__icon {
  display: none;
}

.mat-mdc-form-field {
  width: 100%;
}

.mdc-checkbox {
  box-sizing: content-box !important;
}

.mdc-radio {
  box-sizing: content-box !important;
}

.mat-expansion-panel {
  border: var(--arl-border) !important;
  background: var(--arl-black-color) !important;
}
.mat-expansion-panel:first-of-type {
  border-top-right-radius: var(--arl-radius) !important;
  border-top-left-radius: var(--arl-radius) !important;
}
.mat-expansion-panel:last-of-type {
  border-bottom-right-radius: var(--arl-radius) !important;
  border-bottom-left-radius: var(--arl-radius) !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: none !important;
}

.mat-mdc-tab-group.is-header .mat-mdc-tab-body {
  padding: var(--arl-spacing-m);
}
.mat-mdc-tab-group.is-header .mdc-tab {
  height: auto !important;
  padding: var(--arl-spacing-m) var(--arl-spacing-l) !important;
}
.mat-mdc-tab-group.is-header .mdc-tab__text-label {
  font-size: var(--arl-font-size-xl);
  line-height: var(--arl-font-size-xl);
  font-family: var(--arl-serif-font);
  font-weight: normal;
  text-transform: uppercase;
  color: var(--arl-light-secondary-color);
}

.mat-mdc-tab-group:not(.is-header) .mat-mdc-tab-header {
  margin-bottom: var(--arl-spacing-m);
  border-radius: var(--arl-radius);
  background: var(--arl-background-black-dark);
}

.mat-mdc-slider {
  min-width: auto !important;
  width: calc(100% - 2 * var(--arl-spacing-m)) !important;
  margin-left: var(--arl-spacing-m) !important;
  margin-right: var(--arl-spacing-m) !important;
}
@media screen and (max-width: 1023px) {
  .mat-mdc-slider {
    width: calc(100% - 2 * var(--arl-spacing-s)) !important;
    margin-left: var(--arl-spacing-s) !important;
    margin-right: var(--arl-spacing-s) !important;
  }
}

.mat-ripple {
  display: none;
}

.mat-mdc-table {
  --mat-table-header-headline-font: var(--arl-sans-serif-font);
  --mat-table-row-item-label-text-font: var(--arl-sans-serif-font);
  border: var(--arl-border) !important;
  border-radius: var(--arl-radius) !important;
  background: var(--arl-background-black-dark) !important;
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-header-row,
.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-table .mat-mdc-header-cell {
  background: none !important;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: var(--arl-spacing-s) var(--arl-spacing-m) !important;
}
@media screen and (max-width: 674px) {
  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: var(--arl-spacing-s) !important;
  }
}

.mat-mdc-header-cell {
  text-transform: uppercase !important;
  color: var(--arl-secondary-color) !important;
  font-weight: 600 !important;
}

.mdc-table-container {
  max-width: 100%;
  overflow: auto;
}

.mat-mdc-header-cell.mod-borderLeft,
.mat-mdc-cell.mod-borderLeft,
.mat-mdc-footer-cell.mod-borderLeft {
  border-left-color: var(--mat-table-row-item-outline-color, var(--mat-sys-outline, rgba(0, 0, 0, 0.12)));
  border-left-width: var(--mat-table-row-item-outline-width, 1px);
  border-left-style: solid;
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --mat-sys-on-surface: initial;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-sys-background, transparent));
  color: var(--mat-app-text-color, var(--mat-sys-on-background, inherit));
}

html {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #f1e179;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #f1e179;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

html {
  --mat-optgroup-label-text-color: white;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #f1e179;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
html {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f1e179;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #f1e179;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f1e179;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #f1e179;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f1e179;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

html {
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-elevated-card-container-shape: 4px;
}
html {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
html {
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #f1e179;
  --mdc-linear-progress-track-color: rgba(241, 225, 121, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #f1e179;
  --mdc-linear-progress-track-color: rgba(241, 225, 121, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
html {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
html {
  --mdc-filled-text-field-caret-color: #f1e179;
  --mdc-filled-text-field-focus-active-indicator-color: #f1e179;
  --mdc-filled-text-field-focus-label-text-color: rgba(241, 225, 121, 0.87);
  --mdc-filled-text-field-container-color: rgb(73.56, 73.56, 73.56);
  --mdc-filled-text-field-disabled-container-color: rgb(69.78, 69.78, 69.78);
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
}
html {
  --mdc-outlined-text-field-caret-color: #f1e179;
  --mdc-outlined-text-field-focus-outline-color: #f1e179;
  --mdc-outlined-text-field-focus-label-text-color: rgba(241, 225, 121, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
}
html {
  --mat-form-field-focus-select-arrow-color: rgba(241, 225, 121, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #f1e179;
  --mdc-filled-text-field-focus-active-indicator-color: #f1e179;
  --mdc-filled-text-field-focus-label-text-color: rgba(241, 225, 121, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: #f1e179;
  --mdc-outlined-text-field-focus-outline-color: #f1e179;
  --mdc-outlined-text-field-focus-label-text-color: rgba(241, 225, 121, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: rgba(241, 225, 121, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(241, 225, 121, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(241, 225, 121, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: #424242;
}

html {
  --mdc-dialog-container-shape: 4px;
}
html {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-elevated-selected-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-elevated-disabled-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-flat-disabled-selected-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #f1e179;
  --mdc-chip-elevated-selected-container-color: #f1e179;
  --mdc-chip-elevated-disabled-container-color: #f1e179;
  --mdc-chip-flat-disabled-selected-container-color: #f1e179;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-selected-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: black;
  --mat-chip-selected-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #f1e179;
  --mdc-chip-elevated-selected-container-color: #f1e179;
  --mdc-chip-elevated-disabled-container-color: #f1e179;
  --mdc-chip-flat-disabled-selected-container-color: #f1e179;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-selected-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: black;
  --mat-chip-selected-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #f5eaa1;
  --mdc-switch-selected-handle-color: #f5eaa1;
  --mdc-switch-selected-hover-state-layer-color: #f5eaa1;
  --mdc-switch-selected-pressed-state-layer-color: #f5eaa1;
  --mdc-switch-selected-focus-handle-color: #f8f0bc;
  --mdc-switch-selected-hover-handle-color: #f8f0bc;
  --mdc-switch-selected-pressed-handle-color: #f8f0bc;
  --mdc-switch-selected-focus-track-color: #efdd71;
  --mdc-switch-selected-hover-track-color: #efdd71;
  --mdc-switch-selected-pressed-track-color: #efdd71;
  --mdc-switch-selected-track-color: #efdd71;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: white;
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #f5eaa1;
  --mdc-switch-selected-handle-color: #f5eaa1;
  --mdc-switch-selected-hover-state-layer-color: #f5eaa1;
  --mdc-switch-selected-pressed-state-layer-color: #f5eaa1;
  --mdc-switch-selected-focus-handle-color: #f8f0bc;
  --mdc-switch-selected-hover-handle-color: #f8f0bc;
  --mdc-switch-selected-pressed-handle-color: #f8f0bc;
  --mdc-switch-selected-focus-track-color: #efdd71;
  --mdc-switch-selected-hover-track-color: #efdd71;
  --mdc-switch-selected-pressed-track-color: #efdd71;
  --mdc-switch-selected-track-color: #efdd71;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f1e179;
  --mdc-radio-selected-hover-icon-color: #f1e179;
  --mdc-radio-selected-icon-color: #f1e179;
  --mdc-radio-selected-pressed-icon-color: #f1e179;
}
.mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f1e179;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f1e179;
  --mdc-radio-selected-hover-icon-color: #f1e179;
  --mdc-radio-selected-icon-color: #f1e179;
  --mdc-radio-selected-pressed-icon-color: #f1e179;
}
.mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f1e179;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}

html {
  --mdc-radio-state-layer-size: 40px;
}
html {
  --mat-radio-touch-target-display: block;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #f1e179;
  --mdc-slider-focus-handle-color: #f1e179;
  --mdc-slider-hover-handle-color: #f1e179;
  --mdc-slider-active-track-color: #f1e179;
  --mdc-slider-inactive-track-color: #f1e179;
  --mdc-slider-with-tick-marks-inactive-container-color: #f1e179;
  --mdc-slider-with-tick-marks-active-container-color: black;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
}
html {
  --mat-slider-ripple-color: #f1e179;
  --mat-slider-hover-state-layer-color: rgba(241, 225, 121, 0.05);
  --mat-slider-focus-state-layer-color: rgba(241, 225, 121, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
}
html .mat-accent {
  --mdc-slider-handle-color: #f1e179;
  --mdc-slider-focus-handle-color: #f1e179;
  --mdc-slider-hover-handle-color: #f1e179;
  --mdc-slider-active-track-color: #f1e179;
  --mdc-slider-inactive-track-color: #f1e179;
  --mdc-slider-with-tick-marks-inactive-container-color: #f1e179;
  --mdc-slider-with-tick-marks-active-container-color: black;
}
html .mat-accent {
  --mat-slider-ripple-color: #f1e179;
  --mat-slider-hover-state-layer-color: rgba(241, 225, 121, 0.05);
  --mat-slider-focus-state-layer-color: rgba(241, 225, 121, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
html {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f1e179;
  --mdc-radio-selected-hover-icon-color: #f1e179;
  --mdc-radio-selected-icon-color: #f1e179;
  --mdc-radio-selected-pressed-icon-color: #f1e179;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f1e179;
  --mdc-radio-selected-hover-icon-color: #f1e179;
  --mdc-radio-selected-icon-color: #f1e179;
  --mdc-radio-selected-pressed-icon-color: #f1e179;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #f1e179;
  --mdc-checkbox-selected-hover-icon-color: #f1e179;
  --mdc-checkbox-selected-icon-color: #f1e179;
  --mdc-checkbox-selected-pressed-icon-color: #f1e179;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f1e179;
  --mdc-checkbox-selected-hover-state-layer-color: #f1e179;
  --mdc-checkbox-selected-pressed-state-layer-color: #f1e179;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #f1e179;
  --mdc-checkbox-selected-hover-icon-color: #f1e179;
  --mdc-checkbox-selected-icon-color: #f1e179;
  --mdc-checkbox-selected-pressed-icon-color: #f1e179;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f1e179;
  --mdc-checkbox-selected-hover-state-layer-color: #f1e179;
  --mdc-checkbox-selected-pressed-state-layer-color: #f1e179;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #f1e179;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
html {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
html {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f1e179;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f1e179;
  --mat-tab-header-active-ripple-color: #f1e179;
  --mat-tab-header-inactive-ripple-color: #f1e179;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f1e179;
  --mat-tab-header-active-hover-label-text-color: #f1e179;
  --mat-tab-header-active-focus-indicator-color: #f1e179;
  --mat-tab-header-active-hover-indicator-color: #f1e179;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #f1e179;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f1e179;
  --mat-tab-header-active-ripple-color: #f1e179;
  --mat-tab-header-inactive-ripple-color: #f1e179;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f1e179;
  --mat-tab-header-active-hover-label-text-color: #f1e179;
  --mat-tab-header-active-focus-indicator-color: #f1e179;
  --mat-tab-header-active-hover-indicator-color: #f1e179;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary,
.mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #f1e179;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-accent,
.mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #f1e179;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-warn,
.mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #f1e179;
  --mdc-checkbox-selected-hover-icon-color: #f1e179;
  --mdc-checkbox-selected-icon-color: #f1e179;
  --mdc-checkbox-selected-pressed-icon-color: #f1e179;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f1e179;
  --mdc-checkbox-selected-hover-state-layer-color: #f1e179;
  --mdc-checkbox-selected-pressed-state-layer-color: #f1e179;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
html {
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-checkbox-label-text-color: white;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #f1e179;
  --mdc-checkbox-selected-hover-icon-color: #f1e179;
  --mdc-checkbox-selected-icon-color: #f1e179;
  --mdc-checkbox-selected-pressed-icon-color: #f1e179;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f1e179;
  --mdc-checkbox-selected-hover-state-layer-color: #f1e179;
  --mdc-checkbox-selected-pressed-state-layer-color: #f1e179;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}
html {
  --mat-checkbox-touch-target-display: block;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
html {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
html {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
html {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
html {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
html {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
html {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html {
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
}
html {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
}
html {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
}
html {
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
html {
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #f1e179;
}
.mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #f1e179;
  --mat-text-button-ripple-color: rgba(241, 225, 121, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #f1e179;
}
.mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #f1e179;
  --mat-text-button-ripple-color: rgba(241, 225, 121, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #f1e179;
  --mdc-filled-button-label-text-color: black;
}
.mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #f1e179;
  --mdc-filled-button-label-text-color: black;
}
.mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #f1e179;
  --mdc-protected-button-label-text-color: black;
}
.mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #f1e179;
  --mdc-protected-button-label-text-color: black;
}
.mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #f1e179;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #f1e179;
  --mat-outlined-button-ripple-color: rgba(241, 225, 121, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #f1e179;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #f1e179;
  --mat-outlined-button-ripple-color: rgba(241, 225, 121, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
}
html {
  --mdc-filled-button-container-height: 36px;
}
html {
  --mdc-protected-button-container-height: 36px;
}
html {
  --mdc-outlined-button-container-height: 36px;
}
html {
  --mat-text-button-touch-target-display: block;
}
html {
  --mat-filled-button-touch-target-display: block;
}
html {
  --mat-protected-button-touch-target-display: block;
}
html {
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
html {
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #f1e179;
}
html .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: #f1e179;
  --mat-icon-button-ripple-color: rgba(241, 225, 121, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #f1e179;
}
html .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: #f1e179;
  --mat-icon-button-ripple-color: rgba(241, 225, 121, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
}
html .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: #424242;
}
html {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
}
html {
  --mdc-fab-small-container-color: #424242;
}
html {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #f1e179;
}
html .mat-mdc-fab.mat-primary {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #f1e179;
}
html .mat-mdc-fab.mat-accent {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
}
html .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #f1e179;
}
html .mat-mdc-mini-fab.mat-primary {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #f1e179;
}
html .mat-mdc-mini-fab.mat-accent {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
}
html .mat-mdc-mini-fab.mat-warn {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
}
html {
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: rgb(217.2, 217.2, 217.2);
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
}
html {
  --mat-snack-bar-button-color: #f1e179;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #f1e179;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #f1e179;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #f1e179;
  --mat-badge-text-color: black;
  --mat-badge-disabled-state-background-color: rgb(110.1, 110.1, 110.1);
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
}

.mat-badge-accent {
  --mat-badge-background-color: #f1e179;
  --mat-badge-text-color: black;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
html {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
}
html {
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: rgb(88.68, 88.68, 88.68);
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #f1e179;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(241, 225, 121, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(241, 225, 121, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(241, 225, 121, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #f1e179;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(241, 225, 121, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #f1e179;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(241, 225, 121, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(241, 225, 121, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(241, 225, 121, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(241, 225, 121, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #f1e179;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #f1e179;
}
.mat-icon.mat-accent {
  --mat-icon-color: #f1e179;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #f1e179;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #f1e179;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #f1e179;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #f1e179;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #f1e179;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #f1e179;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: rgb(198.3, 198.3, 198.3);
}

html {
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #f1e179;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #f1e179;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: #424242;
  --mat-tree-node-text-color: white;
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-timepicker-container-background-color: #424242;
}

@font-face {
  font-family: "Noto Serif";
  src: url("../assets/fonts/NotoSerif/NotoSerif-Regular.ttf");
}
@font-face {
  font-family: "Noto Serif";
  font-style: italic;
  src: url("../assets/fonts/NotoSerif/NotoSerif-Italic.ttf");
}
@font-face {
  font-family: "Noto Serif";
  font-weight: bold;
  src: url("../assets/fonts/NotoSerif/NotoSerif-Bold.ttf");
}
@font-face {
  font-family: "Noto Serif";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/fonts/NotoSerif/NotoSerif-BoldItalic.ttf");
}
@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  src: url("../assets/fonts/Montserrat/Montserrat-Italic.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  src: url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf");
}
@font-face {
  font-family: "IBMPlexSans";
  src: url("../assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf");
}
@font-face {
  font-family: "IBMPlexSans";
  font-style: italic;
  src: url("../assets/fonts/IBMPlexSans/IBMPlexSans-Italic.ttf");
}
@font-face {
  font-family: "IBMPlexSans";
  font-weight: bold;
  src: url("../assets/fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf");
}
@font-face {
  font-family: "IBMPlexSans";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.ttf");
}
:root {
  --arl-white-color: #e2e2e2;
  --arl-black-color: #020408;
  --arl-background-black-dark: rgba(2, 4, 8, 0.75);
  --arl-background-black-light: rgba(2, 4, 8, 0.15);
  --arl-dark-primary-color: rgb(17.0338983051, 64.7288135593, 83.4661016949);
  --arl-primary-color: #1e7293;
  --arl-secondary-color: #f1e179;
  --arl-light-secondary-color: rgb(244.6182432432, 232.7533783784, 155.6317567568);
  --arl-red-color: hsl(0, 80%, 50%);
  --arl-orange-color: hsl(20, 80%, 50%);
  --arl-yellow-color: hsl(50, 90%, 60%);
  --arl-green-color: hsl(100, 70%, 40%);
  --arl-turquoise-color: hsl(150, 70%, 40%);
  --arl-cyan-color: hsl(180, 80%, 50%);
  --arl-ocean-color: hsl(210, 80%, 50%);
  --arl-blue-color: hsl(250, 80%, 50%);
  --arl-violet-color: hsl(280, 90%, 60%);
  --arl-pink-color: hsl(320, 90%, 60%);
  --arl-grey-color: hsl(30, 20%, 80%);
  --arl-border: 0.1rem solid var(--arl-secondary-color);
  --arl-border-double: 0.5rem double var(--arl-secondary-color);
  --arl-border-black: 0.1rem solid var(--arl-black-color);
  --arl-border-white: 0.1rem solid var(--arl-white-color);
  --arl-border-info: 0.1rem solid var(--arl-ocean-color);
  --arl-border-warning: 0.1rem solid var(--arl-red-color);
  --arl-gradient-vert: linear-gradient(0deg, #020408, rgba(2, 4, 8, 0.7), rgba(2, 4, 8, 0.4), rgba(30, 114, 147, 0.4), rgba(30, 114, 147, 0.4));
  --arl-gradient-diag: linear-gradient(135deg, #020408, rgba(2, 4, 8, 0.7), rgba(2, 4, 8, 0.4), rgba(30, 114, 147, 0.4), rgba(30, 114, 147, 0.4));
  --arl-gradient-black: linear-gradient(0deg, rgba(2, 4, 8, 0.8), rgba(2, 4, 8, 0.8), rgba(2, 4, 8, 0.8));
  --arl-gradient-max-black: linear-gradient(0deg, rgba(2, 4, 8, 0), rgba(2, 4, 8, 0), #020408);
  --arl-serif-font: "Noto Serif", Georgia, serif;
  --arl-sans-serif-font: "Montserrat", Helvetica, sans-serif;
  --arl-font-size-xxl: 3.2rem;
  --arl-line-height-xxl: 3.8rem;
  --arl-font-size-xl: 2rem;
  --arl-line-height-xl: 2.2rem;
  --arl-font-size-l: 1.25rem;
  --arl-line-height-l: 1.4rem;
  --arl-font-size-m: 0.9rem;
  --arl-line-height-m: 1.25rem;
  --arl-font-size-s: 0.8rem;
  --arl-line-height-s: 1.1rem;
  --arl-spacing-l: 2rem;
  --arl-spacing-m: 1rem;
  --arl-spacing-s: 0.5rem;
  --arl-spacing-xs: 0.25rem;
  --arl-radius: 0.5rem;
  --arl-nav-height: 3rem;
  --arl-input-height: 2.5rem;
}
@media screen and (max-width: 674px) {
  :root {
    --arl-font-size-xxl: 2.4rem;
    --arl-line-height-xxl: 2.8rem;
    --arl-font-size-xl: 1.6rem;
    --arl-line-height-xl: 1.8rem;
    --arl-font-size-l: 1.15rem;
    --arl-line-height-l: 1.25rem;
  }
}

* {
  box-sizing: border-box !important;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  z-index: 1;
  font-family: var(--arl-sans-serif-font);
  font-size: var(--arl-font-size-m);
  line-height: var(--arl-line-height-m);
  color: var(--arl-white-color);
  background: var(--arl-black-color);
}
body *::-webkit-scrollbar {
  height: var(--arl-spacing-s);
  width: var(--arl-spacing-s);
}
body *::-webkit-scrollbar-track {
  background: none;
}
body *::-webkit-scrollbar-thumb {
  border-radius: var(--arl-radius);
}

h1,
.u-h1 {
  margin: 0;
  font-family: var(--arl-serif-font);
  font-size: var(--arl-font-size-xxl);
  line-height: var(--arl-line-height-xxl);
  font-weight: normal;
  text-transform: uppercase;
  color: var(--arl-light-secondary-color);
}
@media screen and (max-width: 674px) {
  h1,
  .u-h1 {
    text-align: center;
  }
}

h2,
.u-h2 {
  margin: 0;
  font-family: var(--arl-serif-font);
  font-size: var(--arl-font-size-xl);
  line-height: var(--arl-line-height-xl);
  font-weight: normal;
  text-transform: uppercase;
  color: var(--arl-light-secondary-color);
}
@media screen and (max-width: 674px) {
  h2,
  .u-h2 {
    text-align: center;
  }
}

h3,
.u-h3 {
  margin: 0;
  font-family: var(--arl-sans-serif-font);
  font-size: var(--arl-font-size-l);
  line-height: var(--arl-line-height-l);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--arl-white-color);
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0 0 0 1rem;
}

a {
  color: var(--arl-white-color);
  text-decoration: none;
}

img {
  outline: none;
  user-select: none;
}

::-webkit-scrollbar {
  width: var(--arl-spacing-s);
}

::-webkit-scrollbar-track {
  background: var(--arl-black-color);
}

::-webkit-scrollbar-thumb {
  background: var(--arl-white-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--arl-light-secondary-color);
}

input[type=file] {
  display: none;
}

table .col-05 {
  width: 5%;
}
table .col-10 {
  width: 10%;
}
table .col-20 {
  width: 20%;
}
table .col-30 {
  width: 30%;
}
table .col-40 {
  width: 40%;
}
table .col-50 {
  width: 50%;
}
table .col-55 {
  width: 55%;
}
table .col-60 {
  width: 60%;
}
table .col-70 {
  width: 70%;
}
table .col-80 {
  width: 80%;
}
table .col,
table .col-100 {
  width: 100%;
}

.u-posRelative {
  position: relative;
}

.u-displayNone {
  display: none;
}

.u-width100 {
  width: 100% !important;
}

.u-width50 {
  width: 50% !important;
}
@media screen and (max-width: 1023px) {
  .u-width50 {
    width: 100% !important;
  }
}

.u-flexRow {
  display: flex;
  flex-direction: row;
  gap: var(--arl-spacing-m);
}

.u-flexColumn {
  display: flex;
  flex-direction: column;
  gap: var(--arl-spacing-m);
}

@media screen and (max-width: 1023px) {
  .u-flexColumn-on-tablet {
    display: flex;
    flex-direction: column;
    gap: var(--arl-spacing-m);
  }
}

@media screen and (max-width: 674px) {
  .u-flexColumn-on-mobile {
    display: flex;
    flex-direction: column;
    gap: var(--arl-spacing-m);
  }
}

.u-flexWrap {
  flex-wrap: wrap;
}

.u-alignStart {
  align-items: flex-start;
}

.u-alignCenter {
  align-items: center;
}

.u-alignEnd {
  align-items: flex-end;
}

@media screen and (max-width: 1023px) {
  .u-alignCenter-on-tablet {
    align-items: center;
  }
}

@media screen and (max-width: 674px) {
  .u-alignCenter-on-mobile {
    align-items: center;
  }
}

.u-justifyCenter {
  justify-content: center;
}

.u-justifyBetween {
  justify-content: space-between;
}

.u-gap0 {
  gap: 0 !important;
}

.u-gap05 {
  gap: var(--arl-spacing-s) !important;
}

.u-gap1 {
  gap: var(--arl-spacing-m) !important;
}

.u-gap2 {
  gap: var(--arl-spacing-l) !important;
}

.u-margin0 {
  margin: 0 !important;
}

.u-marginAuto {
  margin: auto !important;
}

.u-marginTop2 {
  margin-top: var(--arl-spacing-l) !important;
}

.u-marginTop1 {
  margin-top: var(--arl-spacing-m) !important;
}

.u-marginTop05 {
  margin-top: var(--arl-spacing-s) !important;
}

.u-marginTop0 {
  margin-top: 0 !important;
}

.u-marginBottom2 {
  margin-bottom: var(--arl-spacing-l) !important;
}

.u-marginBottom1 {
  margin-bottom: var(--arl-spacing-m) !important;
}

.u-marginBottom05 {
  margin-bottom: var(--arl-spacing-s) !important;
}

.u-marginBottom025 {
  margin-bottom: var(--arl-spacing-xs) !important;
}

.u-marginBottom0 {
  margin-bottom: 0 !important;
}

.u-marginRightAuto {
  margin-right: auto !important;
}

.u-marginRight05 {
  margin-right: var(--arl-spacing-s) !important;
}

.u-marginRight1 {
  margin-right: var(--arl-spacing-m) !important;
}

.u-marginLeftAuto {
  margin-left: auto !important;
}

.u-marginLeft05 {
  margin-left: var(--arl-spacing-s) !important;
}

.u-marginLeft1 {
  margin-left: var(--arl-spacing-m) !important;
}

.u-textBold {
  font-weight: bold;
}

.u-textLeft {
  text-align: left !important;
}

.u-textCenter {
  text-align: center !important;
}

.u-textRight {
  text-align: right !important;
}

.u-textItalic {
  font-style: italic;
}

.u-textCapitalize {
  text-transform: capitalize;
}

.u-textUppercase {
  text-transform: uppercase;
}

.u-textLowercase {
  text-transform: lowercase;
}

.u-textEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.u-textXXL {
  font-size: var(--arl-font-size-xxl);
}

.u-textSecondary {
  color: var(--arl-secondary-color);
}

.u-textRed {
  color: var(--arl-red-color);
}

.u-textOrange {
  color: var(--arl-orange-color);
}

.u-textYellow {
  color: var(--arl-yellow-color);
}

.u-textGreen {
  color: var(--arl-green-color);
}

.u-textTurquoise {
  color: var(--arl-turquoise-color);
}

.u-textCyan {
  color: var(--arl-cyan-color);
}

.u-textOcean {
  color: var(--arl-ocean-color);
}

.u-textBlue {
  color: var(--arl-blue-color);
}

.u-textViolet {
  color: var(--arl-violet-color);
}

.u-textPink {
  color: var(--arl-pink-color);
}

.u-textGrey {
  color: var(--arl-grey-color);
}

.u-opacity1 {
  opacity: 1 !important;
}

.u-opacity05 {
  opacity: 0.5;
}

.u-rounded {
  border-radius: 50%;
}

.u-radius {
  border-radius: var(--arl-radius);
}

.u-pointer {
  cursor: pointer;
}

.u-mask {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@media screen and (max-width: 1023px) {
  .hide-on-tablet {
    display: none !important;
  }
}

@media screen and (max-width: 674px) {
  .hide-on-mobile {
    display: none !important;
  }
}

.show-on-tablet {
  display: none !important;
}
@media screen and (max-width: 1023px) {
  .show-on-tablet {
    display: block !important;
  }
}

.show-on-mobile {
  display: none !important;
}
@media screen and (max-width: 674px) {
  .show-on-mobile {
    display: block !important;
  }
}

[separator] {
  height: var(--arl-spacing-m);
  width: 100%;
  border-top: 0.5rem solid var(--arl-secondary-color);
  border-bottom: 0.1rem solid var(--arl-light-secondary-color);
}

[white-separator] {
  position: relative;
  margin: var(--arl-spacing-m) 0;
  text-align: center;
  font-weight: bold;
}
[white-separator]::before {
  left: calc(2 * var(--arl-spacing-l));
}
[white-separator]::after {
  right: calc(2 * var(--arl-spacing-l));
}
[white-separator]::before, [white-separator]::after {
  position: absolute;
  top: 50%;
  width: calc(50% - 2 * var(--arl-spacing-l) - var(--arl-spacing-m));
  border-radius: 50%;
  border: 0.1rem solid var(--arl-white-color);
  content: "";
}

[comments] {
  font-style: italic;
  color: var(--arl-secondary-color);
}

.layout-view,
.layout-bloc-view,
.layout-full-view {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 15%;
}
@media screen and (max-width: 1535px) {
  .layout-view,
  .layout-bloc-view,
  .layout-full-view {
    padding: 4rem 10%;
  }
}
@media screen and (max-width: 1023px) {
  .layout-view,
  .layout-bloc-view,
  .layout-full-view {
    height: auto;
    width: 100%;
    padding: var(--arl-spacing-l);
  }
}

.layout-view {
  min-height: calc(100dvh - var(--arl-nav-height) * 3);
}

.layout-full-view {
  min-height: calc(100dvh - var(--arl-nav-height) * 2 - 0.1rem);
}

.layout-row {
  display: flex;
  gap: var(--arl-spacing-m);
  width: 100%;
}
@media screen and (max-width: 674px) {
  .layout-row.layout-col-mob {
    flex-direction: column;
  }
}
.layout-row .layout-col-100 {
  width: 100% !important;
}
.layout-row .layout-col-75 {
  width: 75% !important;
}
.layout-row .layout-col-50 {
  width: 50% !important;
}
.layout-row .layout-col-45 {
  width: 45% !important;
}
.layout-row .layout-col-33 {
  width: 33.33% !important;
}
.layout-row .layout-col-25 {
  width: 25% !important;
}

.bg-global::after,
.bg-universe::after,
.bg-roleplay::after,
.bg-roleplay-reverse::after,
.bg-celestia::after,
.bg-celestia-spe::after {
  content: "";
  position: fixed;
  opacity: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -5;
  background-position: center;
  background-size: cover;
}

.bg-block::after {
  position: absolute;
  z-index: -2;
}

.bg-black::before {
  content: "";
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: var(--arl-background-black-dark);
}

.bg-global::after {
  background-image: var(--arl-gradient-diag);
}

.bg-universe::after {
  background-image: var(--arl-gradient-diag), url("../../assets/images_filled/backgrounds/home01_ld.webp");
}
@media screen and (max-width: 674px) {
  .bg-universe::after {
    background-image: var(--arl-gradient-diag), url("../../assets/images_filled/backgrounds/home01_mob.png");
  }
}

.bg-roleplay::after {
  background-image: var(--arl-gradient-diag), url("../../assets/images_filled/backgrounds/home02_ld.webp");
}
@media screen and (max-width: 674px) {
  .bg-roleplay::after {
    background-image: var(--arl-gradient-diag), url("../../assets/images_filled/backgrounds/home02_mob.png");
  }
}

.bg-roleplay-reverse::after {
  transform: scaleX(-1);
  background-image: var(--arl-gradient-diag), url("../../assets/images_filled/backgrounds/home02_ld.webp");
}
@media screen and (max-width: 674px) {
  .bg-roleplay-reverse::after {
    background-image: var(--arl-gradient-diag), url("../../assets/images_filled/backgrounds/home02_mob.png");
  }
}

.bg-celestia::after {
  background-image: var(--arl-gradient-diag), url("../../assets/images_filled/backgrounds/home03_ld.webp");
}
@media screen and (max-width: 674px) {
  .bg-celestia::after {
    background-image: var(--arl-gradient-diag), url("../../assets/images_filled/backgrounds/home03_mob.png");
  }
}

.bg-celestia-spe::after {
  background-image: var(--arl-gradient-max-black), url("../../assets/images_filled/backgrounds/home04_ld.webp");
}
@media screen and (max-width: 674px) {
  .bg-celestia-spe::after {
    background-image: var(--arl-gradient-max-black), url("../../assets/images_filled/backgrounds/home04_mob.png");
  }
}

.card {
  padding: var(--arl-spacing-m);
  border: var(--arl-border);
  border-radius: var(--arl-radius);
  background: var(--arl-background-black-dark);
}
.card.is-info {
  font-weight: bold;
  color: var(--arl-ocean-color);
  border: var(--arl-border-info);
}
.card.is-warning {
  font-weight: bold;
  color: var(--arl-red-color);
  border: var(--arl-border-warning);
}
.card.mod-clickable {
  box-shadow: 0 0 var(--arl-spacing-m) var(--arl-black-color);
  transition: 0.25s ease;
  cursor: pointer;
}
.card.mod-clickable:hover {
  transform: scale(1.025);
}

.form-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-element > span {
  margin-bottom: var(--arl-spacing-s);
}
.form-element > input[type=text],
.form-element > input[type=number] {
  width: 100%;
}

.glyphe-hover:hover .glyphe-view .glyphe01 {
  animation: anim01 5s infinite ease-in-out;
}
@keyframes anim01 {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.glyphe-hover:hover .glyphe-view .glyphe02 {
  animation: anim02 5s infinite ease-in-out;
}
@keyframes anim02 {
  0% {
    transform: rotateY(0deg) rotateX(0deg);
  }
  100% {
    transform: rotateY(360deg) rotateX(360deg);
  }
}
.glyphe-hover:hover .glyphe-view .glyphe03 {
  animation: anim03 5s infinite ease-in-out;
}
@keyframes anim03 {
  0% {
    transform: rotateY(0deg) rotateX(360deg);
  }
  100% {
    transform: rotateY(360deg) rotateX(0deg);
  }
}
.glyphe-hover:hover .glyphe-view .glyphe04 {
  animation: anim04 5s infinite ease-in-out;
}
@keyframes anim04 {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.glyphe-hover:hover .glyphe-view .glyphe05 {
  animation: anim05 5s infinite ease-in-out;
}
@keyframes anim05 {
  0% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.grid-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--arl-spacing-m);
}
.grid-list.grid-sized {
  grid-auto-rows: 1fr;
}
.grid-list.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 1023px) {
  .grid-list.grid-2 {
    grid-template-columns: repeat(1, 1fr);
  }
}
.grid-list.grid-1 {
  grid-template-columns: repeat(1, 1fr);
}
@media screen and (max-width: 1023px) {
  .grid-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 674px) {
  .grid-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.grid-list .grid-element {
  display: flex;
  width: 100%;
  padding: var(--arl-spacing-m);
  text-align: left;
  border: var(--arl-border);
  border-radius: var(--arl-radius);
  border-spacing: 0;
  background: var(--arl-background-black-dark);
}
.grid-list .grid-element img {
  height: 4rem;
  width: 4rem;
  margin-right: var(--arl-spacing-m);
  border: var(--arl-border);
  pointer-events: none;
}

.link {
  position: relative;
  display: inline-block;
  padding: var(--arl-spacing-s) var(--arl-spacing-m);
  text-transform: uppercase;
  cursor: pointer;
}
.link:hover, .link.active-link {
  color: var(--arl-secondary-color);
}

.onglets-nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border: none;
  background: var(--arl-background-black-dark);
}
.onglets-nav .onglets-nav-item {
  flex-basis: 33.3333333333%;
  padding: var(--arl-spacing-m);
  font-family: var(--arl-serif-font);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--arl-secondary-color);
  border: var(--arl-border);
  cursor: pointer;
}
@media screen and (max-width: 674px) {
  .onglets-nav .onglets-nav-item {
    flex-basis: 100%;
  }
}
.onglets-nav .onglets-nav-item.selected {
  color: var(--arl-black-color);
  background: var(--arl-secondary-color);
}

.power-img-layout {
  --height-size: 3rem;
  --padding-size: 0.3rem;
  margin: calc(0rem - var(--padding-size));
  position: relative;
  height: var(--height-size);
  min-width: var(--height-size);
}
.power-img-layout.big {
  --height-size: 10rem;
  --padding-size: 1rem;
  margin: var(--padding-size);
}
.power-img-layout.after::after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: calc(var(--height-size) - var(--padding-size) * 2);
  width: calc(var(--height-size) - var(--padding-size) * 2);
  border: var(--arl-border);
  transform: translate(-50%, -50%) rotate(-45deg);
  content: "";
}
.power-img-layout.before::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: calc(var(--height-size) - var(--padding-size));
  width: calc(var(--height-size) - var(--padding-size));
  border: var(--arl-border);
  transform: translate(-50%, -50%);
  content: "";
}
.power-img-layout .power-img {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  height: calc(var(--height-size) - var(--padding-size) * 2);
  width: calc(var(--height-size) - var(--padding-size) * 2);
  border: var(--arl-border);
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.power-img-layout .power-img-ok {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  height: calc(var(--height-size) - var(--padding-size) * 2);
  width: calc(var(--height-size) - var(--padding-size) * 2);
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.section {
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: auto !important;
}
.section.is-height-free {
  min-height: auto;
  max-height: none;
  width: 50%;
  margin: auto;
}
@media screen and (max-width: 1023px) {
  .section.is-height-free {
    width: 100%;
  }
}
.section.is-height-semifree {
  min-height: auto;
}
@media screen and (max-width: 674px) {
  .section {
    min-height: auto;
    max-height: none;
  }
}
.section .section-image {
  height: 100%;
  width: 100%;
  margin-top: var(--arl-spacing-m);
  mask-image: linear-gradient(to bottom, rgb(0, 0, 0), rgb(0, 0, 0), rgba(0, 0, 0, 0));
}
.section .section-icon {
  height: 8rem !important;
  width: 8rem !important;
  border: var(--arl-border);
}
@media screen and (max-width: 1023px) {
  .section .section-icon {
    height: 6rem !important;
    width: 6rem !important;
  }
}
.section .section-icon.is-little {
  height: 4rem !important;
  width: 4rem !important;
}
.section .section-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section .section-description {
  padding: var(--arl-spacing-m);
  overflow-y: auto;
  border-top: var(--arl-border);
  border-bottom: var(--arl-border);
}
.section .section-actions-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--arl-spacing-m);
  padding: var(--arl-spacing-m);
  border-top: var(--arl-border);
  border-bottom: var(--arl-border);
}

.section-moreinfos {
  display: flex;
  flex-direction: row;
  gap: var(--arl-spacing-m);
  text-align: center;
}
@media screen and (max-width: 674px) {
  .section-moreinfos {
    flex-direction: column;
  }
}
.section-moreinfos > * {
  flex: 1 1 0;
}

.status-red,
.status-orange,
.status-yellow,
.status-green,
.status-turquoise,
.status-cyan,
.status-ocean,
.status-blue,
.status-violet,
.status-pink,
.status-grey {
  padding: var(--arl-spacing-xs) var(--arl-spacing-s);
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--arl-font-size-s);
  border-radius: var(--arl-radius);
}

.status-red {
  color: var(--arl-white-color);
  background: var(--arl-red-color);
}

.status-orange {
  color: var(--arl-black-color);
  background: var(--arl-orange-color);
}

.status-yellow {
  color: var(--arl-black-color);
  background: var(--arl-yellow-color);
}

.status-green {
  color: var(--arl-black-color);
  background: var(--arl-green-color);
}

.status-turquoise {
  color: var(--arl-black-color);
  background: var(--arl-turquoise-color);
}

.status-cyan {
  color: var(--arl-black-color);
  background: var(--arl-cyan-color);
}

.status-ocean {
  color: var(--arl-black-color);
  background: var(--arl-ocean-color);
}

.status-blue {
  color: var(--arl-white-color);
  background: var(--arl-blue-color);
}

.status-violet {
  color: var(--arl-white-color);
  background: var(--arl-violet-color);
}

.status-pink {
  color: var(--arl-black-color);
  background: var(--arl-pink-color);
}

.status-grey {
  color: var(--arl-black-color);
  background: var(--arl-grey-color);
}

.steps-container {
  position: absolute;
  top: 50%;
  right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);
}
.steps-container .dotline {
  height: 4.8rem;
  width: 0.1rem;
  background-color: var(--arl-white-color);
}
.steps-container .dot {
  position: relative;
  display: inline-block;
  height: 1.6rem;
  min-width: 1.6rem;
  width: 1.6rem;
  margin: 0;
  border-radius: 50%;
  border: var(--arl-border-white);
  background-color: var(--arl-background-black-light);
  transition: 1s ease;
  cursor: pointer;
}
.steps-container .dot.active::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.25rem;
  border-radius: 50%;
  background-clip: content-box;
  background-color: var(--arl-light-secondary-color);
  content: "";
}
.steps-container .dot.warning {
  border: var(--arl-border-warning);
}
.steps-container.celestia-steps {
  position: relative;
  right: auto;
  top: auto;
  margin-top: var(--arl-spacing-m);
  flex-direction: row;
  justify-content: center;
  transform: none;
}
.steps-container.celestia-steps .dot {
  height: 1.2rem;
  min-width: 1.2rem;
  width: 1.2rem;
  margin: 0.25rem;
  border-radius: 0;
  transform: rotate(45deg);
  cursor: default;
}
.steps-container.celestia-steps .dot.active::after {
  border-radius: 0 !important;
}
.steps-container.celestia-steps .dotline {
  height: 0.075rem;
  min-width: 1.2rem;
}
@media screen and (max-width: 1023px) {
  .steps-container.celestia-steps .dotline {
    height: 0.0666666667rem;
    min-width: 1.0666666667rem;
  }
  .steps-container.celestia-steps .dot {
    height: 1.0666666667rem;
    min-width: 1.0666666667rem;
    width: 1.0666666667rem;
  }
}
@media screen and (max-width: 674px) {
  .steps-container.celestia-steps {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .steps-container.creation-steps {
    display: none;
  }
}
.steps-container.creation-steps .dot {
  margin: 0.25rem;
  border-radius: 0;
  transform: rotate(45deg);
  cursor: default;
}
.steps-container.creation-steps .dot.active::after {
  border-radius: 0 !important;
}
.steps-container.creation-steps .dotline {
  height: 1.6rem;
}

.side-menu {
  position: absolute;
  top: 0;
  max-width: 100%;
  padding: 0;
  border-top: none !important;
  border-radius: 0 0 var(--arl-radius) var(--arl-radius);
  border: var(--arl-border);
  background: var(--arl-black-color);
  overflow: auto;
}
.side-menu::-webkit-scrollbar {
  height: var(--arl-spacing-s);
}
.side-menu::-webkit-scrollbar-thumb {
  border-radius: 0 0 var(--arl-radius) var(--arl-radius);
}
@media screen and (max-width: 674px) {
  .side-menu {
    border-radius: 0;
  }
  .side-menu::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
}
.side-menu .side-list {
  display: flex;
  flex-direction: row;
}
.side-menu .side-list .side-element {
  display: flex;
  padding: var(--arl-spacing-s) var(--arl-spacing-m);
}
.side-menu .side-list .side-element.selected {
  background: var(--arl-secondary-color);
}
.side-menu .side-list .side-element img {
  height: 3rem;
  width: 3rem;
  border: var(--arl-border);
  background: var(--arl-black-color);
  pointer-events: all;
  cursor: pointer;
}

.sides {
  display: flex;
  flex-direction: row;
  gap: var(--arl-spacing-l);
  width: 100%;
}
@media screen and (max-width: 674px) {
  .sides {
    flex-direction: column;
    gap: var(--arl-spacing-m);
  }
}
.sides .sides-left {
  width: 25%;
}
@media screen and (max-width: 1023px) {
  .sides .sides-left {
    width: 35%;
  }
}
@media screen and (max-width: 674px) {
  .sides .sides-left {
    width: 100%;
  }
}
.sides .sides-left .sides-left-bloc {
  position: sticky;
  top: var(--arl-spacing-m);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--arl-spacing-m);
}
.sides .sides-right {
  width: 75%;
}
@media screen and (max-width: 1023px) {
  .sides .sides-right {
    width: 65%;
  }
}
@media screen and (max-width: 674px) {
  .sides .sides-right {
    width: 100%;
  }
}

.table-line:hover {
  background-color: rgba(var(--arl-white-color), 0.2) !important;
}
.table-line.selected {
  background-color: rgba(var(--arl-white-color), 0.2) !important;
}
.table-line td {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-line img {
  height: 2rem;
  pointer-events: none;
}
.table-line p {
  text-align: left;
  margin: var(--arl-spacing-m);
}

.table-separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% + 2 * var(--arl-spacing-m));
  margin: 0 calc(0rem - var(--arl-spacing-m));
}
.table-separator .table-separator-line {
  display: block;
  height: 0.1rem;
  width: 100%;
  background: var(--arl-secondary-color);
}
.table-separator .table-separator-title {
  display: block;
  padding: var(--arl-spacing-xs) var(--arl-spacing-m);
  text-transform: uppercase;
  white-space: nowrap;
  border: var(--arl-border);
  background: var(--arl-black-color);
}