.card {
  padding: var(--arl-spacing-m);
  border: var(--arl-border);
  border-radius: var(--arl-radius);
  background: var(--arl-background-black-dark);

  &.is-info {
    font-weight: bold;
    color: var(--arl-ocean-color);
    border: var(--arl-border-info);
  }

  &.is-warning {
    font-weight: bold;
    color: var(--arl-red-color);
    border: var(--arl-border-warning);
  }

  &.mod-clickable {
    box-shadow: 0 0 var(--arl-spacing-m) var(--arl-black-color);
    transition: 0.25s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.025);
    }
  }
}
