// Variables pour le responsive
$breakpoints: (
  // Small desktop
  desktop: 1535px,
  tablet: 1023px,
  mobile: 674px
);

@mixin desktop-only {
  @media screen and (max-width: map-get($breakpoints, desktop)) {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (max-width: map-get($breakpoints, tablet)) {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: map-get($breakpoints, mobile)) {
    @content;
  }
}
