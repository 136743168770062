@font-face {
  font-family: 'Noto Serif';
  src: url('../assets/fonts/NotoSerif/NotoSerif-Regular.ttf');
}
@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  src: url('../assets/fonts/NotoSerif/NotoSerif-Italic.ttf');
}
@font-face {
  font-family: 'Noto Serif';
  font-weight: bold;
  src: url('../assets/fonts/NotoSerif/NotoSerif-Bold.ttf');
}
@font-face {
  font-family: 'Noto Serif';
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/NotoSerif/NotoSerif-BoldItalic.ttf');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  src: url('../assets/fonts/Montserrat/Montserrat-Italic.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'IBMPlexSans';
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf');
}
@font-face {
  font-family: 'IBMPlexSans';
  font-style: italic;
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-Italic.ttf');
}
@font-face {
  font-family: 'IBMPlexSans';
  font-weight: bold;
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf');
}
@font-face {
  font-family: 'IBMPlexSans';
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.ttf');
}
