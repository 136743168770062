@use "mixins";

.u-posRelative {
  position: relative;
}

.u-displayNone {
  display: none;
}

.u-width100 {
  width: 100% !important;
}

.u-width50 {
  width: 50% !important;

  @include mixins.tablet-only {
    width: 100% !important;
  }
}

.u-flexRow {
  display: flex;
  flex-direction: row;
  gap: var(--arl-spacing-m);
}

.u-flexColumn {
  display: flex;
  flex-direction: column;
  gap: var(--arl-spacing-m);
}

.u-flexColumn-on-tablet {
  @include mixins.tablet-only {
    display: flex;
    flex-direction: column;
    gap: var(--arl-spacing-m);
  }
}

.u-flexColumn-on-mobile {
  @include mixins.mobile-only {
    display: flex;
    flex-direction: column;
    gap: var(--arl-spacing-m);
  }
}

.u-flexWrap {
  flex-wrap: wrap;
}

.u-alignStart {
  align-items: flex-start;
}

.u-alignCenter {
  align-items: center;
}

.u-alignEnd {
  align-items: flex-end;
}

.u-alignCenter-on-tablet {
  @include mixins.tablet-only {
    align-items: center;
  }
}

.u-alignCenter-on-mobile {
  @include mixins.mobile-only {
    align-items: center;
  }
}

.u-justifyCenter {
  justify-content: center;
}

.u-justifyBetween {
  justify-content: space-between;
}

.u-gap0 {
  gap: 0 !important;
}

.u-gap05 {
  gap: var(--arl-spacing-s) !important;
}

.u-gap1 {
  gap: var(--arl-spacing-m) !important;
}

.u-gap2 {
  gap: var(--arl-spacing-l) !important;
}

.u-margin0 {
  margin: 0 !important;
}

.u-marginAuto {
  margin: auto !important;
}

.u-marginTop2 {
  margin-top: var(--arl-spacing-l) !important;
}

.u-marginTop1 {
  margin-top: var(--arl-spacing-m) !important;
}

.u-marginTop05 {
  margin-top: var(--arl-spacing-s) !important;
}

.u-marginTop0 {
  margin-top: 0 !important;
}

.u-marginBottom2 {
  margin-bottom: var(--arl-spacing-l) !important;
}

.u-marginBottom1 {
  margin-bottom: var(--arl-spacing-m) !important;
}

.u-marginBottom05 {
  margin-bottom: var(--arl-spacing-s) !important;
}

.u-marginBottom025 {
  margin-bottom: var(--arl-spacing-xs) !important;
}

.u-marginBottom0 {
  margin-bottom: 0 !important;
}

.u-marginRightAuto {
  margin-right: auto !important;
}

.u-marginRight05 {
  margin-right: var(--arl-spacing-s) !important;
}

.u-marginRight1 {
  margin-right: var(--arl-spacing-m) !important;
}

.u-marginLeftAuto {
  margin-left: auto !important;
}

.u-marginLeft05 {
  margin-left: var(--arl-spacing-s) !important;
}

.u-marginLeft1 {
  margin-left: var(--arl-spacing-m) !important;
}

.u-textBold {
  font-weight: bold;
}

.u-textLeft {
  text-align: left !important;
}

.u-textCenter {
  text-align: center !important;
}

.u-textRight {
  text-align: right !important;
}

.u-textItalic {
  font-style: italic;
}

.u-textCapitalize {
  text-transform: capitalize;
}

.u-textUppercase {
  text-transform: uppercase;
}

.u-textLowercase {
  text-transform: lowercase;
}

.u-textEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.u-textXXL {
  font-size: var(--arl-font-size-xxl);
}

.u-textSecondary {
  color: var(--arl-secondary-color);
}

.u-textRed {
  color: var(--arl-red-color);
}

.u-textOrange {
  color: var(--arl-orange-color);
}

.u-textYellow {
  color: var(--arl-yellow-color);
}

.u-textGreen {
  color: var(--arl-green-color);
}

.u-textTurquoise {
  color: var(--arl-turquoise-color);
}

.u-textCyan {
  color: var(--arl-cyan-color);
}

.u-textOcean {
  color: var(--arl-ocean-color);
}

.u-textBlue {
  color: var(--arl-blue-color);
}

.u-textViolet {
  color: var(--arl-violet-color);
}

.u-textPink {
  color: var(--arl-pink-color);
}

.u-textGrey {
  color: var(--arl-grey-color);
}

.u-opacity1 {
  opacity: 1 !important;
}

.u-opacity05 {
  opacity: 0.5;
}

.u-rounded {
  border-radius: 50%;
}

.u-radius {
  border-radius: var(--arl-radius);
}

.u-pointer {
  cursor: pointer;
}

.u-mask {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.hide-on-tablet {
  @include mixins.tablet-only {
    display: none !important;
  }
}

.hide-on-mobile {
  @include mixins.mobile-only {
    display: none !important;
  }
}

.show-on-tablet {
  display: none !important;

  @include mixins.tablet-only {
    display: block !important;
  }
}

.show-on-mobile {
  display: none !important;

  @include mixins.mobile-only {
    display: block !important;
  }
}
