@use "mixins";

$dot-size: 1.6rem;
$padding-size: 0.25rem;
$line-size: 0.1rem;

.steps-container {
  position: absolute;
  top: 50%;
  right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);

  .dotline {
    height: calc($dot-size * 3);
    width: $line-size;
    background-color: var(--arl-white-color);
  }

  .dot {
    position: relative;
    display: inline-block;
    height: $dot-size;
    min-width: $dot-size;
    width: $dot-size;
    margin: 0;
    border-radius: 50%;
    border: var(--arl-border-white);
    background-color: var(--arl-background-black-light);
    transition: 1s ease;
    cursor: pointer;

    &.active {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $padding-size;
        border-radius: 50%;
        background-clip: content-box;
        background-color: var(--arl-light-secondary-color);
        content: "";
      }
    }

    &.warning {
      border: var(--arl-border-warning);
    }
  }

  &.celestia-steps {
    position: relative;
    right: auto;
    top: auto;
    margin-top: var(--arl-spacing-m);
    flex-direction: row;
    justify-content: center;
    transform: none;

    .dot {
      height: calc($dot-size / 4 * 3);
      min-width: calc($dot-size / 4 * 3);
      width: calc($dot-size / 4 * 3);
      margin: $padding-size;
      border-radius: 0;
      transform: rotate(45deg);
      cursor: default;

      &.active {
        &::after {
          border-radius: 0 !important;
        }
      }
    }

    .dotline {
      height: calc($line-size / 4 * 3);
      min-width: calc($dot-size / 4 * 3);
    }

    @include mixins.tablet-only {
      .dotline {
        height: calc($line-size / 3 * 2);
        min-width: calc($dot-size / 3 * 2);
      }

      .dot {
        height: calc($dot-size / 3 * 2);
        min-width: calc($dot-size / 3 * 2);
        width: calc($dot-size / 3 * 2);
      }
    }

    @include mixins.mobile-only {
      display: none;
    }
  }

  &.creation-steps {
    @include mixins.tablet-only {
      display: none;
    }

    .dot {
      margin: $padding-size;
      border-radius: 0;
      transform: rotate(45deg);
      cursor: default;

      &.active {
        &::after {
          border-radius: 0 !important;
        }
      }
    }

    .dotline {
      height: $dot-size;
    }
  }
}
