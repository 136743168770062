.link {
  position: relative;
  display: inline-block;
  padding: var(--arl-spacing-s) var(--arl-spacing-m);
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &.active-link {
    color: var(--arl-secondary-color);
  }
}
