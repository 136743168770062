// Inputs...
.mat-mdc-form-field-required-marker {
  margin-left: var(--arl-spacing-xs);
  color: var(--arl-red-color);
}

.mdc-text-field {
  border-radius: var(--arl-spacing-s) !important;
  border: var(--arl-border);
}

.mdc-text-field--filled {
  background: var(--arl-black-color) !important;
}

.mdc-text-field--disabled {
  border-color: rgba(255, 255, 255, 0.38) !important;
}

.mdc-line-ripple {
  display: none !important;
}

// Textarea
.mat-mdc-form-field-textarea-control {
  min-height: 10rem;
}

// Select...
.mdc-menu-surface {
  border-radius: var(--arl-radius) !important;
}

.mat-mdc-option {
  min-height: 36px !important;
}
