// Buttons...
.mdc-button {
  height: auto !important;
  padding: var(--arl-spacing-s) var(--arl-spacing-m) !important;
  background: var(--arl-background-black-light) !important;

  .mdc-button__label {
    display: inline-flex;
    align-items: center;
    gap: var(--arl-spacing-s);
  }
}

.mdc-icon-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.mat-mdc-icon-button {
  font-family: var(--arl-sans-serif-font);
  border-width: 0.1rem !important;
  border-style: solid !important;

  &:not(:disabled) {
    border-color: var(--mdc-icon-button-icon-color, inherit) !important;
  }

  &.is-small {
    --mdc-icon-button-state-layer-size: 36px !important;
    --mdc-icon-button-icon-size: 18px !important;
    padding: 8px !important;
  }
}

.mat-mdc-outlined-button {
  text-transform: uppercase !important;

  &:not(:disabled) {
    border-color: var(--mdc-outlined-button-label-text-color, inherit) !important;
  }
}
