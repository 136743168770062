.status-red,
.status-orange,
.status-yellow,
.status-green,
.status-turquoise,
.status-cyan,
.status-ocean,
.status-blue,
.status-violet,
.status-pink,
.status-grey {
  padding: var(--arl-spacing-xs) var(--arl-spacing-s);
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--arl-font-size-s);
  border-radius: var(--arl-radius);
}

.status-red {
  color: var(--arl-white-color);
  background: var(--arl-red-color);
}

.status-orange {
  color: var(--arl-black-color);
  background: var(--arl-orange-color);
}

.status-yellow {
  color: var(--arl-black-color);
  background: var(--arl-yellow-color);
}

.status-green {
  color: var(--arl-black-color);
  background: var(--arl-green-color);
}

.status-turquoise {
  color: var(--arl-black-color);
  background: var(--arl-turquoise-color);
}

.status-cyan {
  color: var(--arl-black-color);
  background: var(--arl-cyan-color);
}

.status-ocean {
  color: var(--arl-black-color);
  background: var(--arl-ocean-color);
}

.status-blue {
  color: var(--arl-white-color);
  background: var(--arl-blue-color);
}

.status-violet {
  color: var(--arl-white-color);
  background: var(--arl-violet-color);
}

.status-pink {
  color: var(--arl-black-color);
  background: var(--arl-pink-color);
}

.status-grey {
  color: var(--arl-black-color);
  background: var(--arl-grey-color);
}
