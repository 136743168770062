.table-line {
  &:hover {
    background-color: rgba(var(--arl-white-color), 0.2) !important;
  }

  &.selected {
    background-color: rgba(var(--arl-white-color), 0.2) !important;
  }

  td {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    height: 2rem;
    pointer-events: none;
  }

  p {
    text-align: left;
    margin: var(--arl-spacing-m);
  }
}

.table-separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% + 2 * var(--arl-spacing-m));
  margin: 0 calc(0rem - var(--arl-spacing-m));

  .table-separator-line {
    display: block;
    height: 0.1rem;
    width: 100%;
    background: var(--arl-secondary-color);
  }

  .table-separator-title {
    display: block;
    padding: var(--arl-spacing-xs) var(--arl-spacing-m);
    text-transform: uppercase;
    white-space: nowrap;
    border: var(--arl-border);
    background: var(--arl-black-color);
  }
}
