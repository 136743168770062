@use "mixins";

// Table...
.mat-mdc-table {
  --mat-table-header-headline-font: var(--arl-sans-serif-font);
  --mat-table-row-item-label-text-font: var(--arl-sans-serif-font);
  border: var(--arl-border) !important;
  border-radius: var(--arl-radius) !important;
  background: var(--arl-background-black-dark) !important;
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-header-row,
.mat-mdc-row,
.mat-mdc-footer-row,
.mat-mdc-table .mat-mdc-header-cell {
  background: none !important;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: var(--arl-spacing-s) var(--arl-spacing-m) !important;

  @include mixins.mobile-only {
    padding: var(--arl-spacing-s) !important;
  }
}

.mat-mdc-header-cell {
  text-transform: uppercase !important;
  color: var(--arl-secondary-color) !important;
  font-weight: 600 !important;
}

.mdc-table-container {
  max-width: 100%;
  overflow: auto;
}

.mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
  &.mod-borderLeft {
    border-left-color: var(--mat-table-row-item-outline-color, var(--mat-sys-outline, rgba(0, 0, 0, 0.12)));
    border-left-width: var(--mat-table-row-item-outline-width, 1px);
    border-left-style: solid;
  }
}
