.glyphe-hover:hover .glyphe-view {
  .glyphe01 {
    animation: anim01 5s infinite ease-in-out;

    @keyframes anim01 {
      0% {
        transform: rotateY(0deg);
      }
      100% {
        transform: rotateY(360deg);
      }
    }
  }

  .glyphe02 {
    animation: anim02 5s infinite ease-in-out;

    @keyframes anim02 {
      0% {
        transform: rotateY(0deg) rotateX(0deg);
      }
      100% {
        transform: rotateY(360deg) rotateX(360deg);
      }
    }
  }

  .glyphe03 {
    animation: anim03 5s infinite ease-in-out;

    @keyframes anim03 {
      0% {
        transform: rotateY(0deg) rotateX(360deg);
      }
      100% {
        transform: rotateY(360deg) rotateX(0deg);
      }
    }
  }

  .glyphe04 {
    animation: anim04 5s infinite ease-in-out;

    @keyframes anim04 {
      0% {
        transform: rotateY(360deg);
      }
      100% {
        transform: rotateY(0deg);
      }
    }
  }

  .glyphe05 {
    animation: anim05 5s infinite ease-in-out;

    @keyframes anim05 {
      0% {
        transform: rotateX(360deg);
      }
      100% {
        transform: rotateX(0deg);
      }
    }
  }
}
