.form-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > span {
    margin-bottom: var(--arl-spacing-s);
  }

  > input[type='text'],
  > input[type='number'] {
    width: 100%;
  }
}
