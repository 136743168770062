@use "mixins";

.section {
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: auto !important;

  &.is-height-free {
    min-height: auto;
    max-height: none;
    width: 50%;
    margin: auto;

    @include mixins.tablet-only {
      width: 100%;
    }
  }

  &.is-height-semifree {
    min-height: auto;
  }

  @include mixins.mobile-only {
    min-height: auto;
    max-height: none;
  }

  .section-image {
    height: 100%;
    width: 100%;
    margin-top: var(--arl-spacing-m);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  .section-icon {
    height: 8rem !important;
    width: 8rem !important;
    border: var(--arl-border);

    @include mixins.tablet-only {
      height: 6rem !important;
      width: 6rem !important;
    }

    &.is-little {
      height: 4rem !important;
      width: 4rem !important;
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section-description {
    padding: var(--arl-spacing-m);
    overflow-y: auto;
    border-top: var(--arl-border);
    border-bottom: var(--arl-border);
  }

  .section-actions-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: var(--arl-spacing-m);
    padding: var(--arl-spacing-m);
    border-top: var(--arl-border);
    border-bottom: var(--arl-border);
  }
}

.section-moreinfos {
  display: flex;
  flex-direction: row;
  gap: var(--arl-spacing-m);
  text-align: center;

  @include mixins.mobile-only {
    flex-direction: column;
  }

  & > * {
    flex: 1 1 0;
  }
}
