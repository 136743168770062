@use "mixins";

// Popup
.cdk-overlay-pane {
  max-width: 50vw !important;

  @include mixins.mobile-only {
    max-width: 90vw !important;
  }
}

// Toggle...
.mdc-switch__icon {
  display: none;
}

// Form...
.mat-mdc-form-field {
  width: 100%;
}

// Checkbox...
.mdc-checkbox {
  box-sizing: content-box !important;
}

// Radio...
.mdc-radio {
  box-sizing: content-box !important;
}

// Accordion...
.mat-expansion-panel {
  border: var(--arl-border) !important;
  background: var(--arl-black-color) !important;

  &:first-of-type {
    border-top-right-radius: var(--arl-radius) !important;
    border-top-left-radius: var(--arl-radius) !important;
  }

  &:last-of-type {
    border-bottom-right-radius: var(--arl-radius) !important;
    border-bottom-left-radius: var(--arl-radius) !important;
  }
}

// Steppers...
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background: none !important;
}

// Tabs
.mat-mdc-tab-group.is-header {
  .mat-mdc-tab-body {
    padding: var(--arl-spacing-m);
  }

  .mdc-tab {
    height: auto !important;
    padding: var(--arl-spacing-m) var(--arl-spacing-l) !important;
  }

  .mdc-tab__text-label {
    font-size: var(--arl-font-size-xl);
    line-height: var(--arl-font-size-xl);
    font-family: var(--arl-serif-font);
    font-weight: normal;
    text-transform: uppercase;
    color: var(--arl-light-secondary-color);
  }
}

.mat-mdc-tab-group:not(.is-header) {
  .mat-mdc-tab-header {
    margin-bottom: var(--arl-spacing-m);
    border-radius: var(--arl-radius);
    background: var(--arl-background-black-dark);
  }
}
