@use "mixins";
@use "sass:color";

$white: #e2e2e2;
$black: #020408;
$primary: #1e7293;
$secondary: #f1e179;

$dark-primary: color.adjust($primary, $lightness: -15%);
$light-secondary: color.adjust($secondary, $lightness: 7.5%);

$background-black-dark: rgba($black, 0.75);
$background-black-light: rgba($black, 0.15);

$gradient-vert: linear-gradient(
  0deg,
  rgba($black, 1),
  rgba($black, 0.7),
  rgba($black, 0.4),
  rgba($primary, 0.4),
  rgba($primary, 0.4)
);

$gradient-diag: linear-gradient(
  135deg,
  rgba($black, 1),
  rgba($black, 0.7),
  rgba($black, 0.4),
  rgba($primary, 0.4),
  rgba($primary, 0.4)
);

$gradient-black: linear-gradient(0deg, rgba($black, 0.8), rgba($black, 0.8), rgba($black, 0.8));
$gradient-max-black: linear-gradient(0deg, rgba($black, 0), rgba($black, 0), rgba($black, 1));

:root {
  --arl-white-color: #{$white};
  --arl-black-color: #{$black};
  --arl-background-black-dark: #{$background-black-dark};
  --arl-background-black-light: #{$background-black-light};

  --arl-dark-primary-color: #{$dark-primary};
  --arl-primary-color: #{$primary};
  --arl-secondary-color: #{$secondary};
  --arl-light-secondary-color: #{$light-secondary};

  --arl-red-color: hsl(0, 80%, 50%);
  --arl-orange-color: hsl(20, 80%, 50%);
  --arl-yellow-color: hsl(50, 90%, 60%);
  --arl-green-color: hsl(100, 70%, 40%);
  --arl-turquoise-color: hsl(150, 70%, 40%);
  --arl-cyan-color: hsl(180, 80%, 50%);
  --arl-ocean-color: hsl(210, 80%, 50%);
  --arl-blue-color: hsl(250, 80%, 50%);
  --arl-violet-color: hsl(280, 90%, 60%);
  --arl-pink-color: hsl(320, 90%, 60%);
  --arl-grey-color: hsl(30, 20%, 80%);

  --arl-border: 0.1rem solid var(--arl-secondary-color);
  --arl-border-double: 0.5rem double var(--arl-secondary-color);
  --arl-border-black: 0.1rem solid var(--arl-black-color);
  --arl-border-white: 0.1rem solid var(--arl-white-color);
  --arl-border-info: 0.1rem solid var(--arl-ocean-color);
  --arl-border-warning: 0.1rem solid var(--arl-red-color);

  --arl-gradient-vert: #{$gradient-vert};
  --arl-gradient-diag: #{$gradient-diag};
  --arl-gradient-black: #{$gradient-black};
  --arl-gradient-max-black: #{$gradient-max-black};

  --arl-serif-font: "Noto Serif", Georgia, serif;
  --arl-sans-serif-font: "Montserrat", Helvetica, sans-serif;

  --arl-font-size-xxl: 3.2rem;
  --arl-line-height-xxl: 3.8rem;
  --arl-font-size-xl: 2rem;
  --arl-line-height-xl: 2.2rem;
  --arl-font-size-l: 1.25rem;
  --arl-line-height-l: 1.4rem;
  --arl-font-size-m: 0.9rem;
  --arl-line-height-m: 1.25rem;
  --arl-font-size-s: 0.8rem;
  --arl-line-height-s: 1.1rem;

  --arl-spacing-l: 2rem;
  --arl-spacing-m: 1rem;
  --arl-spacing-s: 0.5rem;
  --arl-spacing-xs: 0.25rem;

  --arl-radius: 0.5rem;

  --arl-nav-height: 3rem;
  --arl-input-height: 2.5rem;

  @include mixins.mobile-only {
    --arl-font-size-xxl: 2.4rem;
    --arl-line-height-xxl: 2.8rem;
    --arl-font-size-xl: 1.6rem;
    --arl-line-height-xl: 1.8rem;
    --arl-font-size-l: 1.15rem;
    --arl-line-height-l: 1.25rem;
  }
}
