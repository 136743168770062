@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?o9pau7');
  src:  url('fonts/icomoon.eot?o9pau7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?o9pau7') format('truetype'),
    url('fonts/icomoon.woff?o9pau7') format('woff'),
    url('fonts/icomoon.svg?o9pau7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dice-five:before {
  content: "\e909";
}
.icon-dice-four:before {
  content: "\e90a";
}
.icon-dice-one:before {
  content: "\e90b";
}
.icon-dice-six:before {
  content: "\e90c";
}
.icon-dice-three:before {
  content: "\e90d";
}
.icon-dice-two:before {
  content: "\e90e";
}
.icon-external-link:before {
  content: "\e908";
}
.icon-address-book:before {
  content: "\e944";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-chevron-down:before {
  content: "\e906";
}
.icon-chevron-up:before {
  content: "\e907";
}
.icon-discord:before {
  content: "\e904";
}
.icon-book:before {
  content: "\e91f";
}
.icon-price-tags:before {
  content: "\e936";
}
.icon-compass:before {
  content: "\e94a";
}
.icon-users:before {
  content: "\e972";
}
.icon-search:before {
  content: "\e986";
}
.icon-magic-wand:before {
  content: "\e997";
}
.icon-accessibility:before {
  content: "\e9b2";
}
.icon-tree:before {
  content: "\e9bc";
}
.icon-chevron-right:before {
  content: "\e902";
}
.icon-chevron-left:before {
  content: "\e903";
}
.icon-folder-plus:before {
  content: "\e931";
}
.icon-folder-minus:before {
  content: "\e932";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-arrow-left:before {
  content: "\ea40";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-menu:before {
  content: "\e901";
}
.icon-logo:before {
  content: "\e900";
}
.icon-user:before {
  content: "\e971";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-github:before {
  content: "\eab0";
}
.icon-arrow-right:before {
  content: "\ea3c";
}
.icon-shield:before {
  content: "\e9b4";
}
.icon-power:before {
  content: "\e9b5";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-heart:before {
  content: "\e9da";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-dice:before {
  content: "\e915";
}
.icon-folder-download:before {
  content: "\e933";
}
.icon-folder-upload:before {
  content: "\e934";
}
.icon-pushpin:before {
  content: "\e946";
}
.icon-location:before {
  content: "\e947";
}
.icon-location-ok:before {
  content: "\e948";
}
.icon-hour-glass:before {
  content: "\e979";
}
.icon-spinner:before {
  content: "\e982";
}
.icon-enlarge:before {
  content: "\e989";
}
.icon-bin:before {
  content: "\e9ad";
}
.icon-arrow-up:before {
  content: "\ea3a";
}
.icon-arrow-down:before {
  content: "\ea3e";
}
.icon-leaf:before {
  content: "\e9a4";
}
