@use "mixins";

// Slider...
.mat-mdc-slider {
  min-width: auto !important;
  width: calc(100% - 2 * var(--arl-spacing-m)) !important;
  margin-left: var(--arl-spacing-m) !important;
  margin-right: var(--arl-spacing-m) !important;

  @include mixins.tablet-only {
    width: calc(100% - 2 * var(--arl-spacing-s)) !important;
    margin-left: var(--arl-spacing-s) !important;
    margin-right: var(--arl-spacing-s) !important;
  }
}

.mat-ripple {
  display: none;
}
